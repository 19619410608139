<template>
  <div>
    <div class="vx-row margin-btm" style="width: 100%; padding-bottom: 20px">
      <div class="vx-col sm:w-1/3 w-full">
        <span>Credit Note Code</span>
      </div>
      <div class="vx-col sm:w-2/3 w-full">
        {{ selected.Code }}
      </div>
    </div>
    <div class="vx-row margin-btm" style="width: 100%; padding-bottom: 20px">
      <div class="vx-col sm:w-1/3 w-full">
        <span>Customer Return</span>
      </div>
      <div class="vx-col sm:w-2/3 w-full">
        {{ selected.SalesReturnCode == "" ?  "- (Credit Note Manual)":selected.SalesReturnCode }}
      </div>
    </div>
    <div class="vx-row margin-btm" style="width: 100%; padding-bottom: 20px">
      <div class="vx-col sm:w-1/3 w-full">
        <span>Customer</span>
      </div>
      <div class="vx-col sm:w-2/3 w-full">
        {{ selected.CustomerCode }} - {{ selected.CustomerName }}
      </div>
    </div>
    <div v-if="selected.SalesReturnCode == ''" class="vx-row margin-btm" style="width: 100%;padding-bottom:15px;">
      <div class="vx-col sm:w-1/3 w-full">
        
        <span>Amount</span>
      </div>
      <div class="vx-col sm:w-2/3 w-full">
        <vs-input
          class="w-full"
          v-model="selected.TotalValue"
          @keyup="selected.TotalValue = priceFormat(selected.TotalValue)"
        />
      </div>
    </div>
    <vs-divider v-if="selected.SalesReturnCode != ''" style="width: 100%; margin-left: 2%">List Item</vs-divider>

    <table v-if="selected.SalesReturnCode != ''" class="table">
      <thead>
        <th>Item Code</th>
        <th>Item Name</th>
        <th>Qty</th>
        <th>SO Value</th>
        <th>CN Value</th>
      </thead>
      <template v-for="line in lines">
        <tr v-bind:key="line.ID">
          <td>{{ line.ItemCode }}</td>
          <td>{{ line.ItemName }}</td>
          <td>{{ line.Qty }}</td>
          <td>
            {{
              priceFormat(
                (line.PriceSo * line.Qty +
                  (line.PriceSo * line.Qty * line.TaxRate) / 100).toString()
              )
            }}
          </td>
          <td>
            <vs-input
          class="w-full"
          v-model="line.Total"
          @keyup="line.Total = priceFormat(line.Total.toString())"
          @input="line.Total = priceFormat(line.Total.toString())"
        />
          </td>
        </tr>
      </template>
    </table>
    <vs-divider style="width: 100%; margin-left: 2%"></vs-divider>
    <div class="vx-row margin-btm" style="width: 100%; margin-left: 0%">

      <vs-button class="mr-4" @click="handleReject()" color="danger" icon-pack="feather" icon="icon-x">
        Reject
      </vs-button>
      <vs-button class="mr-4" @click="handleSave()" icon-pack="feather" icon="icon-save">
        Save
      </vs-button>
      <vs-button @click="handleApprove()" color="success" icon-pack="feather" icon="icon-check">
        Save & Approve
      </vs-button>
    </div>
  </div>
</template>
<script>
import { dataCreditNote, multipleUpdateStatus, updateCreditNote } from "../../../../../services/api/credit_note";
import moment from "moment";
export default {
  props: {
    selected: Object,
    option: Object,
  },
  components: {},
  data() {
    return {
      lines: [],
    };
  },
  computed: {},
  watch: {
    "selected.ID"() {
      this.selected.TotalValue = this.priceFormat(this.selected.TotalValue.toString())
      console.log(this.selected);
    },
  },
  mounted() {
    this.reloadData();
  },
  methods: {
    dateFormat(date) {
      if (date) {
        return moment(String(date)).format("MM/DD/YYYY hh:mm");
      }
    },
    addValidate(ID) {
      console.log(this.validate.length);
      if (this.validate.includes(ID)) {
        this.validate.splice(this.validate.indexOf(ID), 1);
      } else {
        this.validate.push(ID);
      }
    },
    addAllValidate() {
      console.log(this.data, this.validateAll);
      if (this.validateAll == false) {
        for (var i in this.data) {
          if (!this.validate.includes(this.data[i].ID)) {
            this.validate.push(this.data[i].ID);
          }
        }
      } else {
        this.validate = [];
      }
    },
    reloadData() {
      this.$vs.loading();
      this.selected.TotalValue = this.priceFormat(this.selected.TotalValue.toString())
      const creditNote = dataCreditNote(this.selected);
      creditNote.then((r) => {
        this.$vs.loading.close();
        console.log(r);
        this.lines = r.data.creditNoteLine;
        for (var i in this.lines) {
          this.lines[i].Total = this.priceFormat(this.lines[i].Total)
        }
      });
    },
    
    handleApprove() {
      this.$vs.dialog({
        type: "confirm",
        color: "danger",
        title: `Confirm`,
        text: "Are you sure approve credit note " + this.selected.Code + " ?",
        accept: this.confimApprove,
        cancel: () => {},
      });
    },
    handleReject() {
      this.$vs.dialog({
        type: "confirm",
        color: "danger",
        title: `Confirm`,
        text: "Are you sure reject credit note " + this.selected.Code + " ?",
        accept: this.confimReject,
        cancel: () => {},
      });
    },
    confimReject() {
      const params = {
        credit_note_id: [this.selected.ID],
        status: 2
      }
      this.$vs.loading();
      const updateStatus = multipleUpdateStatus(params)
      updateStatus.then(r => {
        console.log(r)
        this.reloadData(this.params)
        this.$vs.loading.close();
        this.closeDetail()
      })
    },
    confimApprove() {
      let line = []
      for (var i in this.lines) {
        line.push({
          credit_note_line_id:this.lines[i].ID,
          total: parseFloat(this.lines[i].Total.toString().replace(/[^.\d]/g, "")),
        })
      }
      const params = {
        ID: this.selected.ID,
        credit_note_id : this.selected.ID,
        amount: parseFloat(this.selected.TotalValue.toString().replace(/[^.\d]/g, "")),
        status : 1,
        line : line
      }
      console.log(params,"params")
      const updateStatus = updateCreditNote(params)
      updateStatus.then(r => {
        console.log(r)
        this.reloadData(this.params)
        this.$vs.loading.close();
        this.closeDetail()
      })

    },
    handleSave() {
      console.log(this.lines)
      let line = []
      for (var i in this.lines) {
        console.log(this.lines[i])
        line.push({
          credit_note_line_id:this.lines[i].ID,
          total: parseFloat(this.lines[i].Total.toString().replace(/[^.\d]/g, ""))
        });
      }
      const amount = this.selected.TotalValue.toString().replace(/[^.\d]/g, "").toString()
      
      const params = {
        ID: this.selected.ID,
        credit_note_id : this.selected.ID,
        amount: parseFloat(this.selected.TotalValue.toString().replace(/[^.\d]/g, "")),
        status : 3,
        line : line
      }
      const updateStatus = updateCreditNote(params)
      updateStatus.then(r => {
        console.log(r)
        this.reloadData(this.params)
        this.$vs.loading.close();
        this.closeDetail()
      })

    },
    closeDetail() {
      this.$nextTick(() => {
        this.$emit("closeDetail", true);
        this.$vs.loading.close();
      });
    },
    formatPrice(angka, prefix = "") {
      console.log(angka);
      var number_string = angka.toString().replace(/[^,\d]/g, "").toString();
      var split = number_string.split(",");
      split[0] = parseInt(split[0]).toString();
      var sisa = split[0].length % 3;
      var rupiah = split[0].substr(0, sisa);
      var ribuan = split[0].substr(sisa).match(/\d{3}/gi);
      var separator = "";
      if (ribuan) {
        if (sisa) {
          separator = ".";
        } else {
          separator = "";
        }
        rupiah += separator + ribuan.join(".");
      }
      rupiah = split[1] != undefined ? rupiah + "," + split[1] : rupiah;
      return prefix == undefined ? rupiah : rupiah ? "" + rupiah : "";
    },
  },
};
</script>
 <style scoped>
.nonfixed {
  position: inherit;
  padding-left: 20px;
}
.core-enter-active {
  transition: all 0.3s ease;
}
.core-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.core-enter, .core-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
.core {
  -webkit-transform-origin-y: all 1s ease;
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}
.vs-con-table .vs-con-tbody .vs-table--tbody-table {
  font-size: 12px;
}
.vertical-divider {
  border-right: 1px solid #7367f0;
  /* min-height: 800px; */
  /* height: 100%; */
  -webkit-mask-position-y: fixed;
  /* padding: 5px; */
}
.vs-con-table.stripe .tr-values:nth-child(2n) {
  background: beige;
}
.colored {
  border: 1px solid #7367f0;
  position: fixed;
  left: 4%;
  top: 40%;
  max-width: 45%;
  z-index: 999999999999;
  background: antiquewhite;
  background-color: antiquewhite;
}
</style>